import React from 'react'
import { Link } from 'gatsby'

import FormBlock from '../../components/mainComponents/FormBlock/FormBlock'
import Seo from '../../components/Seo'

import * as styles from './styles.module.scss'
import '../../assets/css/stylesheet.css'

import imgOffice from '../../assets/images/jpg/services/dedicated teams Justice IT.jpg'
import imgOffice2 from '../../assets/images/jpg/services/full cicle development Justice IT.jpg'
import imgOffice3 from '../../assets/images/jpg/services/team transfer Justice IT.jpg'
import imgOffice4 from '../../assets/images/macbook-mockup001.png'
import icon1 from '../../assets/images/svg/solutionBlock/icons/Front-end web developer.svg'
import icon2 from '../../assets/images/svg/solutionBlock/icons/Back-end web developer.svg'
import icon3 from '../../assets/images/svg/solutionBlock/icons/Team lead.svg'
import icon4 from '../../assets/images/svg/solutionBlock/icons/Teach lead.svg'
import icon5 from '../../assets/images/svg/solutionBlock/icons/Project manager.svg'
import icon6 from '../../assets/images/svg/solutionBlock/icons/Business analyst.svg'
import icon7 from '../../assets/images/svg/solutionBlock/icons/QA - engineer.svg'
import icon8 from '../../assets/images/svg/solutionBlock/icons/Marketer.svg'
import icon9 from '../../assets/images/svg/solutionBlock/icons/UI-UX Designer.svg'
import number1 from '../../assets/images/svg/solutionBlock/icons/1.svg'
import number2 from '../../assets/images/svg/solutionBlock/icons/2.svg'
import number3 from '../../assets/images/svg/solutionBlock/icons/3.svg'
import number4 from '../../assets/images/svg/solutionBlock/icons/4.svg'
import number5 from '../../assets/images/svg/solutionBlock/icons/5.svg'
import number6 from '../../assets/images/svg/solutionBlock/icons/6.svg'
import number7 from '../../assets/images/svg/solutionBlock/icons/7.svg'
import number8 from '../../assets/images/svg/solutionBlock/icons/8.svg'

import mainImage from '../../assets/images/jpg/seo/What we do-min.jpg'

const cardsArr1 = [
  {
    id: 1,
    src: icon1,
    title: 'Front-end web developer (Middle / Senior)',
  },
  {
    id: 2,
    src: icon2,
    title: 'Back-end web developer (Middle / Senior)',
  },
  {
    id: 3,
    src: icon3,
    title: 'Team lead',
  },
  {
    id: 4,
    src: icon4,
    title: 'Tech lead',
  },
  {
    id: 5,
    src: icon5,
    title: 'Project manager',
  },
  {
    id: 6,
    src: icon6,
    title: 'Business analyst',
  },
  {
    id: 7,
    src: icon7,
    title: 'QA - engineer',
  },
  {
    id: 8,
    src: icon8,
    title: 'Marketer',
  },
  {
    id: 9,
    src: icon9,
    title: 'UI/UX Designer',
  },
]

const cardsArr2 = [
  {
    id: 1,
    src: icon1,
    title: 'Middle / Senior level web developer',
  },
  {
    id: 2,
    src: icon9,
    title: 'UI/UX Designer',
  },
  {
    id: 3,
    src: icon7,
    title: 'QA - engineer',
  },
  {
    id: 4,
    src: icon5,
    title: 'Project manager',
  },
  {
    id: 5,
    src: icon6,
    title: 'Business analyst',
  },
  {
    id: 6,
    src: icon8,
    title: 'Marketer',
  },
]

const cardsArr3 = [
  {
    id: 1,
    src: number1,
    title: 'Product planning',
    text: 'After the UX/UI design a plan of product development and functionality'
  },
  {
    id: 2,
    src: number2,
    title: 'Design',
    text: 'Next, designers study user behavior and build a human-readable interface.'
  },
  {
    id: 3,
    src: number3,
    title: 'Development',
    text: 'Developers act sequentially, dividing the project into smaller tasks.'
  },
  {
    id: 4,
    src: number4,
    title: 'Testing',
    text: 'QA-engineer performs different checks depending on the purpose, and until the time to eliminate all errors.'
  },
  {
    id: 5,
    src: number5,
    title: 'Support',
    text: 'The finished product needs support, whether it\'s questions from customers, or the need to make changes'
  },
]

const cardsArr4 = [
  {
    id: 1,
    src: number1,
    title: 'Identifying a market need',
  },
  {
    id: 2,
    src: number2,
    title: 'Quantifying the opportunity',
  },
  {
    id: 3,
    src: number3,
    title: 'Conceptualizing the product',
  },
  {
    id: 4,
    src: number4,
    title: 'Validating the solution',
  },
  {
    id: 5,
    src: number5,
    title: 'Building the product roadmap',
  },
  {
    id: 6,
    src: number6,
    title: 'Developing a minimum viable product (MVP)',
  },
  {
    id: 7,
    src: number7,
    title: 'Releasing the MVP to users',
  },
  {
    id: 8,
    src: number8,
    title: 'Ongoing iteration based on user feedback and strategic goals',
  },
]

const Card = ({ src, title, text }) => (
  <div className={text? `${styles.outsourcedCardsCardBig} ${styles.outsourcedCardsCard}` : styles.outsourcedCardsCard}>
    <div className={styles.outsourcedCardsCardImgCard}>
      <img src={src} alt="icon" loading="lazy" />
    </div>
    <div className={styles.outsourcedCardsCardTitleCard}>
      <p className={styles.outsourcedCardsCardTitleCardTitle}>{title}</p>
      {text &&
        <p className={styles.outsourcedCardsCardTitleCardText}>{text}</p>
      }
    </div>
  </div>
)

const Index = () => (
  <>
    <Seo
      title="Justice | Services"
      description="Justice is an IT company that develops web and mobile applications in FinTech, E-commerce, Helthcare, Renewable Resourses and Agrotech. Application development, IT-consulting, IT service, website development, respectful relationships, quality work and the accurate performance of our duties."
      htmlAttributes={{
        lang: 'en',
      }}
      url="https://www.it-justice.com/"
      keywords={[
        'Application development',
        'it-consulting',
        'it services',
        'website development',
        'develop a website',
        'web company',
      ]}
      image={mainImage}
    />
    <div className={styles.containerBlockDedicated}>
      <div className="container">
        <div className={styles.dedicatedImgOffice} id="dedicated-teams">
          <div
            className={styles.dedicatedImgOfficeDedicated}
            style={{ marginRight: '20px' }}
          >
            <h1 className={styles.dedicatedImgOfficeDedicatedTitle}>
              <span>Dedicated</span>
              <br />
              <span>teams</span>
              <br />
            </h1>
            <p className={styles.dedicatedImgOfficeDedicatedSubtitle}>
              We are ready to outsource our dedicated team of employees. So, we
              provide you with access to highly qualified specialists who will
              quickly begin to complete your tasks and guarantee high-quality
              work in compliance with deadlines.
            </p>
          </div>
          <div className={styles.dedicatedImgOfficeImgOffice}>
            <div>
              <img src={imgOffice} alt="Dedicated teams" loading="eager" />
            </div>
          </div>
        </div>

        <div className={styles.outsourced}>
          <div className={styles.outsourcedTitle}>
            <h3>
              <span>Outsourced team consists of:</span>
            </h3>
          </div>
          <div className={styles.outsourcedCards}>
            {cardsArr1.map((e, id) => {
              return <Card key={id} src={e.src} title={e.title} />
            })}
          </div>
          <div className={styles.buttonDedicated}>
            <Link
              className={`${styles.button} ${styles.buttonMain} `}
              to="/contacts"
            >
              Hire a team
            </Link>
          </div>
        </div>

        <div className={styles.dedicatedImgOffice} id="team-transfer">
          <div className={styles.dedicatedImgOfficeImgOffice}>
            <div>
              <img src={imgOffice2} alt="Team transfer" loading="lazy" />
            </div>
          </div>
          <div
            className={styles.dedicatedImgOfficeDedicated}
            style={{ marginLeft: '20px' }}
          >
            <h1 className={styles.dedicatedImgOfficeDedicatedTitle}>
              <span>Team</span>
              <br />
              <span>transfer</span>
              <br />
            </h1>
            <p className={styles.dedicatedImgOfficeDedicatedSubtitle}>
              Our company provides specialists for Outstaff. In accordance with
              the individual needs of the customer, we will provide a specific
              specialist.
            </p>
          </div>
        </div>

        <div className={styles.outsourced} style={{ marginBottom: '46px' }}>
          <div className={styles.outsourcedTitle}>
            <h3>
              <span> Positions we offer:</span>
            </h3>
          </div>
          <div className={styles.outsourcedCards}>
            {cardsArr2.map((e, id) => {
              return <Card key={id} src={e.src} title={e.title} />
            })}
          </div>
        </div>

        <div className={styles.dedicatedImgOffice} id="full-cycle-dev">
          <div className={styles.dedicatedImgOfficeDedicated}>
            <h1 className={styles.dedicatedImgOfficeDedicatedTitle}>
              <span>Full cycle</span>
              <br />
              <span>development</span>
              <br />
            </h1>
            <p className={styles.dedicatedImgOfficeDedicatedSubtitle}>
              Our team of specialists will guide you through the full product
              development cycle.
            </p>
          </div>
          <div className={styles.dedicatedImgOfficeImgOffice}>
            <div>
              <img
                src={imgOffice3}
                alt="Full cycle development"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className={styles.outsourced} style={{ marginBottom: '63px' }}>
          <div className={styles.outsourcedTitle}>
            <h3>
              <span>The work algorithm is as follows:</span>
            </h3>
          </div>
          <div className={styles.outsourcedCards}>
            {cardsArr3.map((e, id) => {
              return (
               <Card
                 key={id}
                 src={e.src}
                 title={e.title}
                 text={e.text}
               />
            )
            })}
          </div>
        </div>

        <div className={styles.dedicatedImgOffice} id="full-product-dev">
          <div className={styles.dedicatedImgOfficeImgOffice}>
            <div>
              <img
                src={imgOffice4}
                alt="Full product development"
                loading="lazy"
              />
            </div>
          </div>
          <div
            className={styles.dedicatedImgOfficeDedicated}
            style={{ marginLeft: '20px' }}
          >
            <h1 className={styles.dedicatedImgOfficeDedicatedTitle}>
              <span>Full product</span>
              <br />
              <span>development</span>
              <br />
            </h1>
            <p className={styles.dedicatedImgOfficeDedicatedSubtitle}>
              We offer product development from concept or idea through market
              release and beyond.
            </p>
          </div>
        </div>

        <div className={styles.outsourced} style={{ marginBottom: '240px' }}>
          <div className={styles.outsourcedTitle}>
            <h3>
              <span>The work algorithm is as follows:</span>
            </h3>
          </div>
          <div className={styles.outsourcedCards}>
            {cardsArr4.map((e, id) => {
              return <Card key={id} src={e.src} title={e.title} />
            })}
          </div>
        </div>
      </div>

      <FormBlock />
    </div>
  </>
)

export default Index
