// extracted by mini-css-extract-plugin
export var containerBlockDedicated = "styles-module--container-block-dedicated--4PDzY";
export var dedicatedImgOffice = "styles-module--dedicatedImgOffice--+8t0S";
export var dedicatedImgOfficeDedicated = "styles-module--dedicatedImgOffice-dedicated--QOYpF";
export var dedicatedImgOfficeDedicatedTitle = "styles-module--dedicatedImgOffice-dedicated-title--Eczpg";
export var dedicatedImgOfficeDedicatedSubtitle = "styles-module--dedicatedImgOffice-dedicated-subtitle--FLPVx";
export var dedicatedImgOfficeImgOffice = "styles-module--dedicatedImgOffice-imgOffice--aIng5";
export var outsourced = "styles-module--outsourced--A-E3p";
export var outsourcedTitle = "styles-module--outsourced-title--FdlBF";
export var outsourcedCards = "styles-module--outsourced-cards--KwDJG";
export var outsourcedCardsCard = "styles-module--outsourced-cards-card--reQ3w";
export var outsourcedCardsCardBig = "styles-module--outsourced-cards-card-big--vEGNr";
export var outsourcedCardsCardImgCard = "styles-module--outsourced-cards-card-imgCard--ZdYHR";
export var outsourcedCardsCardTitleCard = "styles-module--outsourced-cards-card-titleCard--IUY02";
export var outsourcedCardsCardTitleCardTitle = "styles-module--outsourced-cards-card-titleCard-title--PCDda";
export var buttonDedicated = "styles-module--button-dedicated--8BEQc";
export var button = "styles-module--button--dAMBM";
export var buttonFlared = "styles-module--button-flared--uxxt-";
export var buttonMain = "styles-module--button-main--zKieH";
export var buttonLink = "styles-module--button-link--wLKQX";
export var buttonArrow = "styles-module--button-arrow--7fOdJ";
export var buttonTransparent = "styles-module--button-transparent--TyIos";
export var buttonGray = "styles-module--button-gray--pu6HD";
export var outsourcedCardsCardTitleCardText = "styles-module--outsourcedCardsCardTitleCardText--e4dbb";